<template>
  <div
    style="height: inherit"
    class="user-service-details"
  >
    <location-bar v-if="$store.getters['app/windowSize'] <= 767" />
    <!-- <user-addresses @get-store-list="getServiceDetails" /> -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ $t('Showing') }} {{ filterServiceDetails.length }} {{ $t('of') }} {{ serviceDetails.length }} {{ $t('results') }}
              </div>
            </div>
            <div class="view-options d-flex">
              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search..."
              class="search-product"
              @keyup="beginSearch"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="serviceDetails && serviceDetails.length === 0"
      class="mx-auto"
    >
      <img
        class="no-store-found-image"
        :src="require('@/assets/images/app-images/NoOptionAvailable.svg')"
      >
      <p class="text-center mt-3">
        {{ $t('Sorry, Currently no options are available for your selection') }}
      </p>
    </div>

    <!-- Prodcuts -->
    <section
      v-if="serviceDetails && serviceDetails.length > 0"
      :class="itemView"
    >
      <b-card
        v-for="product in filterServiceDetails"
        :key="product.id"
        class="ecommerce-card fix-footer-product-card"
        no-body
      >
        <div class="item-img text-center">
          <figure
            @mouseenter="$superApp.helper.zoomMagic"
            @mouseout="$superApp.helper.zoomOut"
          >
            <v-zoomer
              :zoomed.sync="$superApp.zoomed"
              :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom"
            >
              <b-img
                :alt="`${product.company_name}-${product.id}`"
                fluid
                class="card-img-top"
                :src="$helpers.imageHelper(product.picture)"
                @error="$helpers.imageError"
              />
            </v-zoomer>
          </figure>
        </div>

        <div>
          <!-- Product Details -->
          <b-card-body>
            <div class="item-wrapper">
              <div class="item-rating">
                <ul class="unstyled-list list-inline">
                  <li
                    v-for="star in 5"
                    :key="star"
                    class="ratings-list-item"
                    :class="{ 'ml-25': star - 1 }"
                  >
                    <feather-icon
                      icon="StarIcon"
                      size="16"
                      :class="[{ 'fill-current': star <= product.rating }, star <= product.rating ? 'text-warning' : 'text-muted']"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <h6 class="item-name">
              <b-link class="text-body">
                {{ product.company_name }}
              </b-link>
              <b-card-text class="item-company">
                {{ $t('By') }}
                <b-link class="ml-25">
                  {{ product.first_name }} {{ product.last_name }}
                </b-link>
              </b-card-text>
            </h6>
            <b-card-text class="item-description">
              {{ product.description }}
            </b-card-text>
            <b-card-text class="item-description">
              <p class="mb-25">
                <img
                  style="width: 16px;"
                  :src="require('@/assets/images/app-icons/order.png')"
                >
                {{ $t('Minimum order amount') }}: {{ product.offer_min_amount }} {{ providerCurrency }}
              </p>
              <p class="mb-25">
                <img
                  style="width: 16px;"
                  :src="require('@/assets/images/app-icons/shopping-cart.png')"
                >
                {{ $t('Offer percentage') }}: {{ product.offer_percent }}%
              </p>
            </b-card-text>
          </b-card-body>

          <div class="item-options text-center footer-area-product">
            <b-button
              variant="primary"
              tag="a"
              href="javascript: void(0);"
              class="btn-cart"
              @click="redirectToService(product)"
            >
              <span>{{ $t('View') }}</span>
            </b-button>
          </div>
        </div>
      </b-card>
    </section>

    <portal to="content-renderer-sidebar-detached-left">
      <store-side-bar
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @clear-all-filters="clearAllFilters"
        @price-range-change="priceRangeChanged"
      />
    </portal>
  </div>
</template>

<script>
import { BInputGroup, BFormInput, BInputGroupAppend, BRow, BCol, BCard, BLink, BImg, BCardBody, BCardText, BButton, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader, useResponsiveAppLeftSidebarVisibility } from '@/@core/comp-functions/ui/app'
// import UserAddresses from '@/@core/layouts/shared/UserAddresses.vue'
import eventBus from '@/libs/event-bus'
import LocationBar from '@/layouts/components/LocationBar.vue'
import { useUserUi } from './useUser'
import StoreSideBar from './store/StoreSideBar.vue'

export default {
  components: {
    BFormRadio,
    LocationBar,
    BFormRadioGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BLink,
    BImg,
    BCardBody,
    BCardText,
    BButton,
    StoreSideBar,
    // UserAddresses,
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
    }
  },
  data() {
    return {
      serviceDetails: [],
      filterServiceDetails: [],
      filterOptions: {
        showAllInCard: true,
        selectedQuickFilter: '',
        quickFiltersTitle: 'Multi Range',
        // quickFilters: [
        //   { text: 'All', value: '' },
        //   { text: '<= $10', value: 'less_than_10' },
        //   { text: '$10 - $100', value: 'less_than_100' },
        //   { text: '$100 - $500', value: 'less_than_500' },
        //   { text: '>= $500', value: 'more_than_500' },
        // ],
        quickFilters: [],
        showPriceRangeSlider: false,
        priceRangeSliderValue: 100,
        priceRangeDropdownChanged: '',
        showServiceLocation: true,
        serviceLocationFilter: '',
        serviceLocation: [
          { text: 'All', value: '2' },
          { text: 'At My Location', value: '0' },
          { text: 'At Provider Location', value: '1' },
        ],
        showClearAllButton: true,
        showPriceRange: true,
        priceRange: '',
      },
      searchQuery: '',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      itemView: 'grid-view',
      providerCurrency: '',
    }
  },
  watch: {
    'filterOptions.serviceLocationFilter': {
      handler() {
        this.getServiceDetails()
      },
    },
    'filterOptions.priceRangeDropdownChanged': {
      handler() {
        this.getServiceDetails()
      },
    },
  },
  destroyed() {
    eventBus.$off('location-searched')
    eventBus.$off('new-address-selected')
    window.removeEventListener('resize', this.resizeOccured)
  },
  mounted() {
    eventBus.$on('location-searched', payload => {
      if (!payload.isCustom && payload.geometry) {
        this.getServiceDetails(`latitude=${payload.geometry.location.lat()}&longitude=${payload.geometry.location.lng()}`)
      } else if (payload.isReset) {
        this.getServiceDetails()
      } else if (payload.lat && payload.lng) {
        this.getServiceDetails(`latitude=${payload.lat}&longitude=${payload.lng}`)
      }
    })
    eventBus.$on('new-address-selected', payload => {
      let defaultLatLong = ''
      if (payload && payload.latitude && payload.longitude) {
        defaultLatLong = `latitude=${payload.latitude}&longitude=${payload.longitude}`
      }
      this.getServiceDetails(defaultLatLong)
    })
    this.resizeOccured()
    window.addEventListener('resize', this.resizeOccured)
    this.getServiceDetails()
  },
  methods: {
    resizeOccured() {
      if (window.innerWidth <= 767 && this.itemView === 'grid-view') {
        this.itemView = 'list-view'
      }
    },
    clearAllFilters() {
      this.filterOptions.selectedQuickFilter = ''
      this.filterOptions.priceRangeSliderValue = 100
      this.filterOptions.serviceLocationFilter = ''
      this.filterOptions.priceRangeDropdownChanged = ''
    },
    priceRangeChanged(payload) {
      this.filterOptions.priceRangeDropdownChanged = payload
    },
    redirectToService(product) {
      if (product.third_party === 1) {
        this.$swal({
          title: this.$t('You will be redirected to a third party website'),
          iconHtml: this.$helpers.swalIcon('alert.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            setTimeout(() => {
              window.open(product.third_party_url, '_BLANK')
            }, 100)
          }
        })
      } else {
        this.$router.push({
          path: `/user/service/provider/${product.slug}`,
        })
      }
    },
    getServiceDetails(defaultLatLong = '') {
      showLoader()
      const { userServiceProviders } = useUserUi()
      const extraFilters = []
      if (defaultLatLong) {
        extraFilters.push(defaultLatLong)
      }
      if (window.SuperApp.getters.userCity()) {
        if (extraFilters) extraFilters.push(`city_id=${window.SuperApp.getters.userCity()}`)
        else extraFilters.push(`city_id=${window.SuperApp.getters.userCity()}`)
      }
      if (this.filterOptions.serviceLocationFilter) {
        extraFilters.push(`onsite=${this.filterOptions.serviceLocationFilter}`)
      }
      if (this.filterOptions.priceRangeDropdownChanged) {
        extraFilters.push(`price_range=${this.filterOptions.priceRangeDropdownChanged}`)
      }
      userServiceProviders({
        query: `${this.$route.params.mainService}/${this.$route.params.serviceName}`,
        filters: extraFilters.length > 0 ? `${extraFilters.join('&')}` : '',
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerCurrency = data.responseData.currency
            this.serviceDetails = data.responseData.provider_service
            window.serviceDetails = data.responseData.provider_service
            this.beginSearch()
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    beginSearch() {
      if (this.searchQuery) {
        this.filterServiceDetails = this.serviceDetails.filter(x => x.company_name && x.company_name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      } else {
        this.filterServiceDetails = this.serviceDetails
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style scoped>
.ecommerce-application .grid-view .ecommerce-card .item-description {
  overflow: auto;
  white-space: unset;
  text-overflow: unset;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
  padding-top: 0;
}
@media only screen and (max-width: 767px) {
  .ecommerce-application .list-view .ecommerce-card .item-img {
    padding-top: 0;
    padding-bottom: 0;
  }

  .wrap-address-info.user-addresses {
    margin-bottom: 10px;
  }

  .ecommerce-application .list-view .ecommerce-card .item-options .btn-cart {
    margin-top: 0;
  }

  .ecommerce-application .list-view .ecommerce-card .item-options {
    padding-top: 0;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .user-service-details {
    .grid-view .ecommerce-card .item-img {
      min-height: 10.85rem;

      img {
        height: 150px;
      }
    }

    .fix-footer-product-card {
      padding-bottom: 65px;

      .footer-area-product {
        left: 0;
      }
    }

    .list-view .ecommerce-card {
      grid-template-columns: 1fr 1fr;

      .item-img a {
        height: 100%;

        img {
          height: 100%;
        }
      }

      .item-options .btn-cart {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
